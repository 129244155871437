import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({
  isLoading,
}) => {
  return (
    <>
      <div className='spinner-container' hidden={!isLoading}>
        <ClipLoader
          color={'#8fb0cf'}
          css={{
            display: 'block',
            position: 'fixed',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 25px)',
            zIndex: '2000',
            transform: 'translate(-50%, -50%)',
          }}
          size={50}
        />
        <span className='spinner-text'>
          잠시만 기다려 주세요...
        </span>
      </div>
    </>
  );
};

export default Spinner;